import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const StyledNotFoundPage = styled.div`
  margin: 128px 0;
  text-align: center;
  img {
    margin-bottom: 64px;
  }
`;

export default () => {
  return (
    <Layout>
      <SEO title={'404'} />
      <StyledNotFoundPage>
        <img src={require('../images/404.png')} />
        <h2>
          <FormattedMessage
            id={"Ooooh... we don't have this content... sorry!"}
          />
        </h2>
      </StyledNotFoundPage>
    </Layout>
  );
};
